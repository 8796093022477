import request from '../util/request'
import qs from 'qs'

export default {
    search(keywords) {
        return request({
            url: '/enterprise/search',
            method: 'post',
            data: qs.stringify({
                keywords: keywords
            })
        })
    },
}