<template>
  <van-popup v-model="showPopup" position="bottom" class="enterprise-selector">
    <van-search
      v-if="showSearh"
      v-model="enterpriseName"
      show-action
      placeholder="请输入企业名称（至少输入五个字）"
      @search="onSearch"
      style="padding: 5px"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-picker
      v-if="showPicker"
      title="选择企业"
      show-toolbar
      :columns="enterpriseNameItems"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>
</template>

<script>
import { Popup, Search, Picker, Notify } from "vant";
import TianyanchaApi from "@/api/tianyancha";

export default {
  name: "EnterpriseSelector",
  components: {
    [Popup.name]: Popup,
    [Search.name]: Search,
    [Picker.name]: Picker,
    [Notify.name]: Notify,
  },
  data() {
    return {
      showPopup: false,
      showSearh: false,
      showPicker: false,
      creditCode: "",
      enterpriseName: "",
      enterpriseNameBk: "",
      enterpriseItems: [],
      enterpriseNameItems: [],
    };
  },
  created() {
    this.close();
  },
  methods: {
    open() {
      this.showPopup = true;
      this.showSearh = true;
      this.showPicker = false;
    },
    close() {
      this.showPopup = false;
      this.showSearh = false;
      this.showPicker = false;
    },
    onSearch() {
      if (!this.enterpriseName || this.enterpriseName.trim().length < 5) {
        return;
      }
      if (this.enterpriseName === this.enterpriseNameBk) {
        this.showSearh = false;
        this.showPicker = true;
        return;
      }
      this.enterpriseItems = [];
      this.enterpriseNameItems = [];
      TianyanchaApi.search(this.enterpriseName.trim())
        .then((res) => {
          this.enterpriseItems = res.data;
          this.enterpriseItems.forEach((item) => {
            this.enterpriseNameItems.push(item.name);
          });
          this.showSearh = false;
          this.showPicker = true;
          this.enterpriseNameBk = this.enterpriseName;
        })
        .catch(() => {});
    },
    onConfirm(value, index) {
      this.enterpriseName = value;
      this.creditCode = this.enterpriseItems[index].creditCode;
      const enterprise = {
        creditCode: this.creditCode,
        enterpriseName: this.enterpriseName,
      };
      this.close();
      this.$emit("input", enterprise);
    },
    onCancel() {
      this.showPicker = false;
      this.showSearh = true;
    },
  },
};
</script>

<style scoped>
.enterprise-selector {
  padding: 30px 0;
  color: #ffffff;
  font-size: 36px;
  text-align: center;
}
</style>